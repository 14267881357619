import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { formateDate, readBoolean } from '../utility';
import { deleteSpecialLine, getSpecialLines } from '../store/specialLines/slice';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import ValidationPopup from '../components/ValidationPopup';

function LignesSpeciales(props) {
  document.title = 'Table des Lignes spéciales | Admin Lemissa';

  const history = useHistory();
  const dispatch = useDispatch();
  const specialLines = useSelector((state) => state.specialLines.items);

  const [validationOpen, setvalidationOpen] = useState(false);
  const [validationDeleteId, setvalidationDeleteId] = useState();
  const [validationText, setvalidationText] = useState('');

  const toggleValidation = () => setvalidationOpen(!validationOpen);
  const addHandler = () => history.push('/lignespeciale/ajouter');
  const onDelete = () => dispatch(deleteSpecialLine(validationDeleteId));

  useEffect(() => {
    dispatch(getSpecialLines());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
        useSelectFilter: true,
      },
      {
        Header: 'Dossier',
        accessor: 'dossier',
        useSelectFilter: true,
      },
      {
        Header: 'Type de ligne',
        accessor: 'typeLigne',
        useSelectFilter: true,
      },
      {
        Header: 'Periode de Debut',
        accessor: 'periodeDateDebut',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Periode de Fin',
        accessor: 'periodeDateFin',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Quantité',
        accessor: 'quantite',
      },
      {
        Header: 'Unité',
        accessor: 'unite',
        useSelectFilter: true,
      },
      {
        Header: 'Facture associée',
        // accessor: 'numFacture' // ancienne formulation
        accessor: 'paiementId',
      },
      {
        Header: 'Réglement',
        accessor: 'reglee',
        useSelectFilter: true,
        Cell: (cellProps) => {
          return readBoolean(cellProps.value);
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className='d-flex gap-3'>
              <Link
                to={{
                  pathname: '/lignespeciale/modifier',
                  state: {
                    id: cellProps.row.original.id,
                    username: cellProps.row.original.username,
                  },
                }}
                className='text-success'
              >
                <i className='mdi mdi-pencil font-size-18' id='edittooltip' />
                <UncontrolledTooltip placement='top' target='edittooltip'>
                  Modifier
                </UncontrolledTooltip>
              </Link>
              <Link
                to={{
                  pathname: '/lignespeciale/clone',
                  state: {
                    id: cellProps.row.original.id,
                    username: cellProps.row.original.username,
                  },
                }}
                className='text-primary'
              >
                <i className='mdi mdi-content-copy font-size-18' id='clonetooltip' />
                <UncontrolledTooltip placement='top' target='clonetooltip'>
                  Cloner
                </UncontrolledTooltip>
              </Link>
              <Link
                to='#'
                className='text-danger'
                onClick={() => {
                  setvalidationText(
                    `Etes vous sur de vouloir supprimer ${cellProps.row.original.typeLigne}-${cellProps.row.original.dossier} pour ${cellProps.row.original.username}`,
                  );
                  setvalidationDeleteId(cellProps.row.original.id);
                  toggleValidation();
                }}
              >
                <i className='mdi mdi-delete font-size-18' id='deletetooltip' />
                <UncontrolledTooltip placement='top' target='deletetooltip'>
                  Supprimer
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Admin Lemissa' breadcrumbItem='Table des Lignes Speciales' />
        <TableContainer
          columns={columns}
          data={specialLines}
          isGlobalFilter={true}
          isAddUserList={true}
          handleUserClick={addHandler}
          addUserName='ajouter une ligne speciale'
          customPageSize={20}
          className='custom-header-css'
        />
      </div>
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onDelete}
        text={validationText}
      />
    </div>
  );
}

export default LignesSpeciales;
