import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumb';
import { Card, CardBody, Col, Row, CardTitle } from 'reactstrap';

import { fetchGet } from '../utility/apiFetch';
import { formateDate } from '../utility';

function SpecialLineEditForm(props) {
  document.title = 'Clone Tarif | Parametres';
  const location = useLocation();

  // Variable de récupération de l'objet Ligne spéciale issu de l'écran précédent.
  // const { ligneSpecialeObject } = location.state;
  const [ligneSpec, setligneSpec] = useState();

  // Déclaration variables de MAJ Listes aide selects
  const [users, setUsers] = useState([]); // listes contributeurs

  const [userFactures, setuserFactures] = useState([]);
  const [dossiersListe, setDossiersListe] = useState([]);
  const [ligneTypes, setligneTypes] = useState([]);
  const [unitesListe, setunitesListe] = useState([]);
  const [factureAsso, setfactureAsso] = useState([]);

  // Déclaration variables des champs à mettre à jour
  const [username, setusername] = useState('');
  const [dossier, setDossier] = useState('');
  const [typeLigne, settypeLigne] = useState('');
  const [quantite, setquantite] = useState('');
  const [unite, setunite] = useState('');
  const [periodeDateDebut, setperiodeDateDebut] = useState('');
  const [periodeDateFin, setperiodeDateFin] = useState('');
  const [commentaire, setcommentaire] = useState('');
  const [reglee, setreglee] = useState('');
  const [numFactureId, setnumFactureId] = useState('');

  // * MAJ des listes nécessaires pour les formulaires selects

  // Liste des contributeurs
  useEffect(() => {
    fetchGet('/users')
      .then((res) => setUsers(res))
      .catch((err) => console.log(err));
  }, []);

  // Liste des dossiers
  useEffect(() => {
    fetchGet('/dossiers')
      .then((res) => setDossiersListe(res))
      .catch((err) => console.log(err));
  }, []);

  // Liste des Types de lignes
  useEffect(() => {
    fetchGet('/typelignes')
      .then((res) => setligneTypes(res))
      .catch((err) => console.log(err));
  }, []);

  // Liste des types de documents pour les unités - Penser à ajouter un regroupement de base pour toute Ligne pour les tarifs
  useEffect(() => {
    fetchGet('/regroupements')
      .then((res) => setunitesListe(res))
      .catch((err) => console.log(err));
  }, []);

  // Initialisation de l'objet traité 'Ligne Spéciale' (ligneSpec) à partir de l'id récupérée sur l'écran précédent
  useEffect(() => {
    fetchGet('/lignesspec/' + location.state.id)
      .then((res) => setligneSpec(res))
      .catch((err) => console.log(err));
  }, [location.state]);

  // Initialisation de l'objet User (contributeur) à partir de l'id récupérée sur l'écran précédent
  useEffect(() => {
    fetchGet('/users/user/' + location.state.username)
      .then((res) => setusername(res.username))
      .catch((err) => console.log(err));
  }, [location.state]);

  // Liste simple des factures antérieures du contributeur en cours de traitement
  useEffect(() => {
    fetchGet('/payments/user/simple/' + location.state.username)
      .then((res) => setuserFactures(res.reverse()))
      .catch((err) => console.log(err));
  }, [location.state]);

  // Initialisation à la volée de toutes variables de la facture dès retour de l'API
  useEffect(() => {
    if (ligneSpec) {
      setusername(ligneSpec.username);
      setDossier(ligneSpec.dossier);
      settypeLigne(ligneSpec.typeLigne);
      setperiodeDateDebut(ligneSpec.periodeDateDebut);
      setperiodeDateFin(ligneSpec.periodeDateFin);
      setquantite(ligneSpec.quantite);
      setunite(ligneSpec.unite);
      setcommentaire(ligneSpec.commentaire);
      if (!props.clone) {
        setnumFactureId(ligneSpec.paiementId);
        setreglee(ligneSpec.reglee);
        // Initialisation de la Facture associée à la ligne spéciale en traitement -format affichage option (id, value)
        fetchGet('/payments/simple/' + ligneSpec.paiementId).then((res) => setfactureAsso(res));
      }
    }
  }, [ligneSpec]);

  // Fonction de déclenchement de la mise à jour consécutive au click sur bouton 'Valider'

  const onSubmit = () => {
    const data = {
      username: username !== '' ? username : ligneSpec.username,
      dossier: dossier !== '' ? dossier : ligneSpec.dossier,
      typeLigne: typeLigne !== '' ? typeLigne : ligneSpec.typeLigne,
      quantite: quantite !== '' ? quantite : ligneSpec.quantite,
      unite: unite !== '' ? unite : ligneSpec.unite,
      periodeDateDebut: periodeDateDebut !== '' ? periodeDateDebut : ligneSpec.periodeDateDebut,
      periodeDateFin: periodeDateFin !== '' ? periodeDateFin : ligneSpec.periodeDateFin,
      paiementId: numFactureId !== '' ? numFactureId : null,
      reglee: reglee !== '' ? reglee : false,
      commentaire: commentaire !== '' ? commentaire : '',
    };
    props.onSubmit(location.state.id, data);
  };

  // * Code HTML de la page

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Paramètres' breadcrumbItem='Cloner Ligne Spéciale' />
        <Row>
          {ligneSpec ? (
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className='h4 mb-4'>Info Ligne</CardTitle>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Contributeur</label>
                    <div className='col-md-4'>
                      <select
                        defaultValue={ligneSpec.username}
                        id='contributeurs'
                        className='form-select'
                        onChange={(event) => setusername(event.target.value)}
                      >
                        <option>{ligneSpec.username}</option>
                        {users.map(({ username, prenom, nom }) => (
                          <option value={username}>
                            {username} - {prenom} {nom}{' '}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='dossier' className='col-md-2 col-form-label'>
                      Dossier
                    </label>
                    <div className='col-md-4'>
                      <select
                        defaultValue={ligneSpec.dossier}
                        id='dossier'
                        className='form-select'
                        onChange={(event) => setDossier(event.target.value)}
                      >
                        <option selected>{ligneSpec.dossier}</option>
                        {dossiersListe.map(({ dossier }) => (
                          <option value={dossier}>{dossier}</option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Type de ligne</label>
                    <div className='col-md-4'>
                      <select
                        defaultValue={ligneSpec.typeLigne}
                        id='typeLigne'
                        className='form-select'
                        onChange={(event) => settypeLigne(event.target.value)}
                      >
                        <option selected>{ligneSpec.typeLigne}</option>
                        {ligneTypes.map((ligne) => {
                          if (ligne.dossier === dossier)
                            return <option value={ligne.typeLigne}>{ligne.typeLigne}</option>;
                        })}
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Quantité</label>
                    <div className='col-md-4'>
                      <input
                        className='form-control'
                        type='text'
                        defaultValue={ligneSpec.quantite}
                        onChange={(event) => setquantite(event.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Unité</label>
                    <div className='col-md-4'>
                      <select
                        defaultValue={ligneSpec.unite}
                        id='unite'
                        className='form-select'
                        onChange={(event) => setunite(event.target.value)}
                      >
                        <option selected>{ligneSpec.unite}</option>
                        {unitesListe.map((line) => {
                          if (line.dossier === dossier && line.typeLigne === typeLigne)
                            return <option value={line.typeDoc}>{line.typeDoc}</option>;
                        })}
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Période - Début</label>
                    <div className='col-md-4'>
                      <input
                        className='form-control'
                        type='date'
                        defaultValue={formateDate(
                          new Date(ligneSpec.periodeDateDebut),
                          'YYYY-MM-DD',
                        )}
                        onChange={(event) => setperiodeDateDebut(event.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Période - Fin</label>
                    <div className='col-md-4'>
                      <input
                        className='form-control'
                        type='date'
                        defaultValue={formateDate(new Date(ligneSpec.periodeDateFin), 'YYYY-MM-DD')}
                        onChange={(event) => setperiodeDateFin(event.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Facture de rattachement</label>
                    <div className='col-md-4'>
                      <select
                        defaultValue={factureAsso.id}
                        id='numFactureId'
                        className='form-select'
                        onChange={(event) => setnumFactureId(event.target.value)}
                      >
                        <option selected>{factureAsso.value}</option>
                        <option value=''>Aucune</option>
                        {userFactures.map((item) => (
                          <option value={item.id}>{item.value}</option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label className='col-md-2 col-form-label'>Réglée</label>
                    <div className='col-md-4'>
                      <select
                        defaultValue={props.clone ? null : ligneSpec.reglee}
                        className='form-select'
                        id='reglee-input'
                        onChange={(event) => setreglee(event.target.value)}
                      >
                        <option selected>Choisissez le statut</option>
                        <option value={true}>Oui</option>
                        <option value={false}>Non</option>
                      </select>
                    </div>
                  </Row>
                  <Row className='mb-3'>
                    <label htmlFor='commentaire' className='col-md-2 col-form-label'>
                      Commentaire
                    </label>
                    <div className='col-md-4'>
                      <textarea
                        className='form-control'
                        rows='4'
                        placeholder='Ajouter un commentaire'
                        defaultValue={ligneSpec.commentaire}
                        onChange={(event) => setcommentaire(event.target.value)}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className='d-flex justify-content-center'>
                      <Link
                        to='/lignespeciale'
                        className='m-2'
                        // ref='annuler'
                      >
                        <button className='btn btn-secondary w-md'>Annuler</button>
                      </Link>

                      <button onClick={onSubmit} className='btn btn-primary w-md m-2'>
                        {props.submitText}
                      </button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </div>
    </div>
  );
}

SpecialLineEditForm.defaultProps = {
  submitText: 'Modifier',
  clone: false,
}

export default SpecialLineEditForm;
