import React, { useMemo } from "react";
import { Input } from 'reactstrap';
import Select from "react-select";
import { readBoolean } from "../utility";

export const Filter = ({ column }) => {
  return <div style={{ marginTop: 5 }}>{column.canFilter && column.render(column.useSelectFilter ? "SelectFilter" : "Filter")}</div>;
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`recherche (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] !== null) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Select
      onChange={(e) => {
        setFilter(e.value || undefined);
      }}
      options={[{
        options: [{
          label: '- Tout -',
        }].concat(options.map((option) => ({
          label: typeof option == "boolean" ? readBoolean(option) : option,
          value: option,
        }))),
      }]}
      placeholder="Choisir ..."
      styles={{
        control: (provided) => ({
          ...provided,
          height: '38px',
          fontWeight: '400',
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 8px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px',
        }),
        option: (provided, state) => ({
          ...provided,
          fontWeight: '400',
        }),
      }}
    />
  );
};
