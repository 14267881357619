import {
  Card,
  CardBody,
  Col, Form, FormFeedback, Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import httpClient from "../../../utility/httpClient";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as yup from 'yup';
import PasswordInput from "../../../components/Input/PasswordInput";

yup.setLocale({
  mixed: {
    required: 'Ceci est un champ obligatoire',
  },
});

function showError() {
  const title = 'Erreur !';
  const message = "Oops! Quelque chose s'est mal passé.";
  toastr.error(message, title);

}

function showSuccess() {
  const title = 'Succès !';
  const message = "La configuration a été mise à jour avec succès.";
  toastr.success(message, title);
}

function OrangeMoneySettings() {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  return (
    <>
      <Row>
        <Col xs='12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h3 className='mb-0 font-size-14'>Paramètres</h3>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col md="3">
                  <Nav pills className="flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleVertical("1");
                        }}
                      >
                        API URL / Access Key
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleVertical("2");
                        }}
                      >
                        API Secret key
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleVertical("3");
                        }}
                      >
                        Changer le mot de passe
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <ApiSettingsForm />
                    </TabPane>
                    <TabPane tabId="2">
                      <ApiSecretForm />
                    </TabPane>
                    <TabPane tabId="3">
                      <ChangePasswordForm />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

function ApiSettingsForm() {
  const [ apiUrl, setApiUrl ] = useState('');
  const [ apiAccessKey, setApiAccessKey ] = useState('');
  const [ apiUrlErrors, setApiUrlErrors ] = useState([]);
  const [ apiAccessKeyErrors, setApiAccessKeyErrors ] = useState([]);
  const [ batchSize, setBatchSize ] = useState(0);
  const [ delayTime, setDelayTime] = useState(0);
  const [ batchSizeErrors, setBatchSizeErrors ] = useState([]);
  const [ delayTimeErrors, setDelayTimeErrors ] = useState([]);

  async function validateApiUrl(value) {
    try {
      await yup.string().required().validate(value);
      setApiUrlErrors([]);
      return true;
    } catch (err) {
      setApiUrlErrors(err.errors);
    }
    return false;
  }

  async function validateApiAccessKey(value) {
    try {
      await yup.string().required().validate(value);
      setApiAccessKeyErrors([]);
      return true;
    } catch (err) {
      setApiAccessKeyErrors(err.errors);
    }
    return false;
  }

  async function validateBatchSize(value) {
    try {
      await yup.number().min(1).required().validate(value);
      setBatchSizeErrors([]);
      return true;
    } catch (err) {
      setBatchSizeErrors(err.errors);
    }
    return false;
  }

  async function validateDelayTime(value) {
    try {
      await yup.number().min(0).required().validate(value);
      setDelayTimeErrors([]);
      return true;
    } catch (err) {
      setDelayTimeErrors(err.errors);
    }
    return false;
  }

  useEffect(() => {
    httpClient.instance.get('/config/om-settings').then(res => {
      if (res.data) {
        setApiUrl(res.data.apiUrl);
        setApiAccessKey(res.data.apiAccessKey);
        setBatchSize(res.data.batchSize);
        setDelayTime(res.data.delayTime);
      }
    })
  }, []);

  const onSubmit = async () => {
    if (await validateApiUrl(apiUrl)
      && await validateApiAccessKey(apiAccessKey)
      && await validateBatchSize(batchSize)
      && await validateDelayTime(delayTime)
    ) {
      httpClient.instance.post('/config/om-settings', { settings: {
        apiUrl, apiAccessKey, batchSize, delayTime
      } })
        .then(() => showSuccess())
        .catch(err => {
          showError();
          console.log(err);
        })
    }
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <label htmlFor="floatingurlInput">URL </label>
            <Input
              type="text"
              className="form-control"
              id="floatingurlInput"
              value={apiUrl}
              onChange={e => setApiUrl(e.target.value)}
              invalid={apiUrlErrors.length > 0 ? true : undefined}
            />
            {apiUrlErrors.map((error) => (
              <FormFeedback type='invalid' key={error}>
                {error}
              </FormFeedback>
            ))}
            <p>Pas de barre oblique finale</p>
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <label htmlFor="floatingapikeyInput">Clé d'accès</label>
            <Input
              type="text"
              className="form-control"
              id="floatingapikeyInput"
              value={apiAccessKey}
              onChange={e => setApiAccessKey(e.target.value)}
              invalid={apiAccessKeyErrors.length > 0 ? true : undefined}
            />
            {apiAccessKeyErrors.map((error) => (
              <FormFeedback type='invalid' key={error}>
                {error}
              </FormFeedback>
            ))}
          </div>
        </Col>
      </Row>
      <fieldset className="mb-4">
        <legend className={"card-title"}>Limitation et Délai des Requêtes API</legend>
      <Row>
        <Col md={3}>
          <div className="mb-3">
            <label htmlFor="floatingurlInput">Taille du lot</label>
            <Input
              type="number"
              className="form-control"
              id="floatingurlInput"
              value={batchSize}
              onChange={e => setBatchSize(e.target.value)}
              invalid={batchSizeErrors.length > 0 ? true : undefined}
            />
            {batchSizeErrors.map((error) => (
              <FormFeedback type="invalid" key={error}>
                {error}
              </FormFeedback>
            ))}
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <label htmlFor="floatingapikeyInput">Temps de délai</label>
            <Input
              type="number"
              className="form-control"
              id="floatingapikeyInput"
              value={delayTime}
              onChange={e => setDelayTime(e.target.value)}
              invalid={delayTimeErrors.length > 0 ? true : undefined}
            />
            <span>ms</span>
            {delayTimeErrors.map((error) => (
              <FormFeedback type="invalid" key={error}>
                {error}
              </FormFeedback>
            ))}
          </div>
        </Col>
      </Row>
      </fieldset>
      <div className='d-flex justify-content-end'>
        <button
          type="button"
          className="btn btn-primary w-md"
          onClick={onSubmit}
        >
          Enregistrer
        </button>
      </div>
    </>
  )
}

function ApiSecretForm() {
  const [ apiSecretKey, setSecretApiKey ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ apiSecretKeyErrors, setApiSecretKeyErrors ] = useState([]);
  const [ passwordErrors, setPasswordErrors ] = useState([]);

  async function validateApiSecretKey(value) {
    try {
      await yup.string().required().validate(value);
      setApiSecretKeyErrors([]);
      return true;
    } catch (err) {
      setApiSecretKeyErrors(err.errors);
    }
    return false;
  }
  async function validatePassword(value) {
    try {
      await yup.string().required().validate(value);
      setPasswordErrors([]);
      return true;
    } catch (err) {
      setPasswordErrors(err.errors);
    }
    return false;
  }

  const onSubmit = async () => {
    if (await validateApiSecretKey(apiSecretKey) && await validatePassword(password)) {
      httpClient.instance.post('/config/om-secret-key', { apiSecretKey, password })
        .then(() => {
          setSecretApiKey('');
          setPassword('');
          showSuccess();
        })
        .catch(err => {
          setSecretApiKey('');
          setPassword('');
          showError();
          console.log(err);
        })
    }
  }

  return (
    <Form>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <label htmlFor="floatingapisecretkeyInput">Clef secrète</label>
            <Input
              type="text"
              className="form-control"
              id="floatingapisecretkeyInput"
              value={apiSecretKey}
              onChange={e => setSecretApiKey(e.target.value)}
              invalid={apiSecretKeyErrors.length > 0 ? true : undefined}
            />
            {apiSecretKeyErrors.map((error) => (
              <FormFeedback type='invalid' key={error}>
                {error}
              </FormFeedback>
            ))}
          </div>
        </Col>
        <Col md={6}>
          <PasswordInput
            label="Mot de passe"
            id="floatingpasswordInput"
            value={password}
            onChange={e => setPassword(e.target.value)}
            errors={passwordErrors}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>* Remplace la clé secrète API actuelle par une nouvelle !</p>
        </Col>
        <Col md={6}>
          <div className='d-flex justify-content-end'>
            <button
              type="button"
              className="btn btn-primary w-md"
              onClick={onSubmit}
            >
              Enregistrer
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

function ChangePasswordForm() {
  const [ currentPassword, setCurrentPassword ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const [ currentPassErrors, setCurrentPassErrors ] = useState([]);
  const [ newPassErrors, setNewPassErrors ] = useState([]);

  async function validateCurrentPass(value) {
    try {
      await yup.string().required().validate(value);
      setCurrentPassErrors([]);
      return true;
    } catch (err) {
      setCurrentPassErrors(err.errors);
    }
    return false;
  }
  async function validateNewPass(value) {
    try {
      await yup.string().required().validate(value);
      setNewPassErrors([]);
      return true;
    } catch (err) {
      setNewPassErrors(err.errors);
    }
    return false;
  }

  const onSubmit = async () => {
    if (await validateCurrentPass(currentPassword) && await validateNewPass(newPassword)) {
      httpClient.instance.put('/config/om-password', { currentPassword, newPassword })
        .then(() => {
          setCurrentPassword('');
          setNewPassword('');
          showSuccess()
        })
        .catch(err => {
          setCurrentPassword('');
          setNewPassword('');
          console.log(err);
          showError()
        })
    }
  }

  return (
    <Form>
      <Row>
        <Col md={6}>
          <PasswordInput
            label="Mot de passe actuel"
            id="floatingcurrentpasswordInput"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            errors={currentPassErrors}
          />
        </Col>
        <Col md={6}>
          <PasswordInput
            label="Nouveau mot de passe"
            id="floatingnewpasswordInput"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            errors={newPassErrors}
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-end'>
        <button
          type="button"
          className="btn btn-primary w-md"
          onClick={onSubmit}
        >
          Enregistrer
        </button>
      </div>
    </Form>
  )
}

export default OrangeMoneySettings;
