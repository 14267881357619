import { call, put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../utility/httpClient';
import {
  getSpecialLines,
  getSpecialLinesSuccess,
  getUserSpecialLines,
  getUserSpecialLinesSuccess,
  deleteSpecialLine,
  deleteSpecialLineSuccess,
} from './slice';
import { handleSagaErrors } from "../../utility/sagaHelpers";

function* getSpecialLinesSaga() {
  const response = yield call(httpClient.instance.get, '/lignesspec');
  if (response.status !== undefined && response.status === 200) {
    yield put(getSpecialLinesSuccess(response.data));
  }
}

function* getUserSpecialLinesSaga(action) {
  const username = action.payload;
  const response = yield call(httpClient.instance.get, `/lignesspec/user/${username}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(getUserSpecialLinesSuccess(response.data));
  }
}

function* deleteSpecialLineSaga(action) {
  const specialLineId = action.payload;
  const response = yield call(httpClient.instance.delete, `/lignesspec/${specialLineId}`);
  if (response.status !== undefined && response.status === 200) {
    yield put(deleteSpecialLineSuccess(specialLineId));
  }
}

export default function* specialLinesSaga() {
  yield takeLatest(getSpecialLines, handleSagaErrors, getSpecialLinesSaga);
  yield takeLatest(getUserSpecialLines, handleSagaErrors, getUserSpecialLinesSaga);
  yield takeLatest(deleteSpecialLine, handleSagaErrors, deleteSpecialLineSaga);
}
