import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';

// i18n
// import { withTranslation } from "react-i18next";

const SidebarContentUser = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = props.location.pathname;
    pathName = pathName.split('/');
    pathName = '/' + pathName[1];
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
      console.log('found his parent ! !');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className='h-100' ref={ref}>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled font-size-24' id='side-menu'>
            <li className='menu-title font-size-14'>Contributeurs </li>
            <li>
              <Link to='/comptelemissa'>
                <i className='bx bx-money'></i>
                <span>Mon compte Lemissa</span>
              </Link>
            </li>
            <li>
              <Link to='/productions/user'>
                <i className='bx bx-money'></i>
                <span>Détail productions</span>
              </Link>
            </li>
            <li>
              <Link to="/user/special-lines">
                <i className="bx bx-money"></i>
                <span>Détail lignes spéciales</span>
              </Link>
            </li>

            {/* Temp Profil si debug Auth0 */}
            {/* <li>
            <Link to="/profil">
              <i className="bx bxs-user"></i>
              <span>Profil Test</span>  
            </Link>
            </li> */}
            <li className='menu-title'>Déconnection </li>
            <li>
              <Link to='/logout'>
                <i className='bx bx-power-off'></i>
                <span>Se déconnecter</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContentUser.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(SidebarContentUser);
