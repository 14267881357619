import React from 'react';
import { useHistory } from 'react-router-dom';
import { fetchPut } from '../utility/apiFetch';
import SpecialLineEditForm from "./SpecialLineEditForm";

function ModifySpecialLine(props) {
  document.title = 'Modifier Tarif | Parametres';
  const history = useHistory();

  const submitModify = (id, data) => {
    fetchPut('/lignesspec/' + id, data)
      .then((res) => {
        history.push('/lignespeciale');
      })
      .catch((err) => console.log(err));
  };
  return (<SpecialLineEditForm {...props} onSubmit={submitModify} />);
}

export default ModifySpecialLine;
