import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formateDate, readBoolean } from '../utility';
import { getUserSpecialLines } from '../store/specialLines/slice';

import Breadcrumbs from '../components/Breadcrumb';
import TableContainer from '../components/TableContainer';
import { useAuth0 } from "@auth0/auth0-react";

function ContributorSpecialLinesList() {
  const name = useAuth0().user?.name ?? null;
  const nickname = useAuth0().user?.nickname ?? null;
  document.title = `Table des Lignes spéciales | Contributeur ${name}`;

  const dispatch = useDispatch();
  const specialLines = useSelector((state) => state.specialLines.items);

  useEffect(() => {
    dispatch(getUserSpecialLines(nickname));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Dossier',
        accessor: 'dossier',
        useSelectFilter: true,
      },
      {
        Header: 'Type de ligne',
        accessor: 'typeLigne',
        useSelectFilter: true,
      },
      {
        Header: 'Periode de Debut',
        accessor: 'periodeDateDebut',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Periode de Fin',
        accessor: 'periodeDateFin',
        Cell: (cellProps) => {
          return formateDate(cellProps.value);
        },
      },
      {
        Header: 'Quantité',
        accessor: 'quantite',
      },
      {
        Header: 'Unité',
        accessor: 'unite',
        useSelectFilter: true,
      },
      {
        Header: 'Réglement',
        accessor: 'reglee',
        Cell: (cellProps) => {
          return readBoolean(cellProps.value);
        },
      },
      {
        Header: 'Commentaire',
        accessor: 'commentaire',
      },
    ],
    [],
  );

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title={`Contributeur ${name}`} breadcrumbItem='Table des Lignes Speciales' />
        <TableContainer
          columns={columns}
          data={specialLines}
          isGlobalFilter={true}
          customPageSize={20}
          className='custom-header-css'
        />
      </div>
    </div>
  );
}

export default ContributorSpecialLinesList;
