import { createSlice } from '@reduxjs/toolkit';

const specialLinesSlice = createSlice({
  name: 'specialLines',
  initialState: { isLoading: false, items: [] },
  reducers: {
    getSpecialLines(state) {
      state.isLoading = true;
    },
    getSpecialLinesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    getUserSpecialLines(state) {
      state.isLoading = true;
    },
    getUserSpecialLinesSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },
    deleteSpecialLine(state) {
      state.isLoading = true;
    },
    deleteSpecialLineSuccess(state, action) {
      state.isLoading = false;
      state.items = state.items.filter((specialLine) => specialLine.id !== action.payload);
    },
  },
});

export const {
  getSpecialLines,
  getSpecialLinesSuccess,
  getUserSpecialLines,
  getUserSpecialLinesSuccess,
  deleteSpecialLine,
  deleteSpecialLineSuccess,
} = specialLinesSlice.actions;
export default specialLinesSlice;
