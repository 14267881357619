import React, { useState } from "react";
import SpecialLineEditForm from "./SpecialLineEditForm";
import { fetchPost } from "../utility/apiFetch";
import { useHistory } from "react-router-dom";
import ValidationPopup from "../components/ValidationPopup";

function CloneSpecialLine(props) {
  document.title = 'Cloner Tarif | Parametres';
  const history = useHistory();

  const [validationOpen, setValidationOpen] = useState(false);
  const [validationText, setValidationText] = useState('');
  const [validationData, setValidationData] = useState({});

  const toggleValidation = () => setValidationOpen(!validationOpen);

  const onSubmit = (id, data) => {
    setValidationText(
      `Etes-vous sûr de vouloir cloner ${data.typeLigne}-${data.dossier} pour ${data.username}`,
    );
    setValidationData(data)
    toggleValidation();
  }

  const onClone = () => {
    fetchPost('/lignesspec', validationData)
      .then((res) => {
        history.push('/lignespeciale');
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <SpecialLineEditForm {...props} onSubmit={onSubmit} submitText="Cloner" clone={true} />
      <ValidationPopup
        isOpen={validationOpen}
        toogle={toggleValidation}
        fct={onClone}
        text={validationText}
      />
    </>
  );
}

export default CloneSpecialLine;
